import {  createRouter, createWebHistory} from 'vue-router'
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            // 主页面
            path: '/',
            name: 'mainHome',
            meta: {
                keepAlive: false,
                isBack: false,
                title:'语数英名师课堂'
            },
            component: () => import('@/View/MainHome/index.vue')
        },
        {
            // 视频播放器
            path: '/VideoPlayer',
            name: 'VideoPlayer',
            meta: {
                keepAlive: false,
                title:'语数英名师课堂'
            },
            component: () => import('@/View/VideoPlayer/index.vue')
        }
    ]
})
export default router;


