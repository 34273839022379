import { createApp } from 'vue'
// 引入组件
import App from './App.vue'
// 引入资源
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import base_css from './assets/css/base.css'
import {request,requestNoKey, requestLogin} from '@/components/network/request'
import api from '@/components/network/common'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import Moment from  'moment'
import "lib-flexible-computer"
import 'video.js/dist/video-js.css'
// 创建app
const app = createApp(App)
// 注入路由
app.use(base_css)


app.config.globalProperties.request = request
app.config.globalProperties.requestNoKey = requestNoKey
app.config.globalProperties. requestLogin =  requestLogin
app.config.globalProperties.api = api
app.config.globalProperties.Moment = Moment
app.use(ElementPlus, { locale })
app.use(router)

// 挂载实例
app.mount('#app')
