import axios from 'axios'
import api from './common'
import md5 from 'js-md5'
import { MD5 } from 'crypto-js';


export function getTime () {
  // eslint-disable-next-line one-var
  let date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
      minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
      second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // eslint-disable-next-line no-unused-expressions
  month >= 1 && month <= 9 ? (month = '0' + month) : ''
  // eslint-disable-next-line no-unused-expressions
  day >= 0 && day <= 9 ? (day = '0' + day) : ''
  var timer = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  return timer
}

export function requestNoKey (config) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: api.apiArray.HTTP_HEAD,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
    })
    config.method = 'post'
    config.data.appKey = api.apiArray.VIDEO_KEY
    // 发送网络请求
    instance(config).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
function getBrowserInfo() {
  const userAgent = navigator.userAgent;
  let browserName, browserVersion;
  // 判断是否是 Chrome 浏览器
  if (/Chrome/.test(userAgent) && !/Edg\//.test(userAgent)) {
    browserName = 'Chrome';
    browserVersion = userAgent.match(/Chrome\/(\d+\.\d+)/)[1];
  }
  // 判断是否是 Firefox 浏览器
  else if (/Firefox/.test(userAgent)) {
    browserName = 'Firefox';
    browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)[1];
  }
  // 判断是否是 Safari 浏览器
  else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
    browserName = 'Safari';
    browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)[1];
  }
  // 判断是否是 Opera 浏览器
  else if (/Opera/.test(userAgent) || /OPR/.test(userAgent)) {
    browserName = 'Opera';
    browserVersion = userAgent.match(/(?:Opera|OPR)\/(\d+\.\d+)/)[1];
  }
  // 判断是否是 IE 浏览器
  else if (/Trident/.test(userAgent)) {
    browserName = 'Internet Explorer';
    browserVersion = userAgent.match(/rv:(\d+\.\d+)/)[1];
  }
  // 判断是否是 Edge 浏览器
  else if (/Edg\//.test(userAgent)) {
    browserName = 'Microsoft Edge';
    browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/)[1];
  } else {
    browserName = 'Unknown';
    browserVersion = 'Unknown';
  }
  return {
    name: browserName,
    version: browserVersion
  };
}
export  function  requestLogin(config) {
  const appid = api.apiArray.APP_ID;
  const appkey = api.apiArray.APP_KEY;
  const clientType = 'web';
  const baoming =  api.apiArray.BAO_NAME;
  const deviceType = 'browser';
  const timestamp = new Date().getTime();
  const deviceVendor = getBrowserInfo().name + getBrowserInfo().version;
  //#endif
  const token = localStorage.getItem('token')?JSON.parse(localStorage.getItem('token')):null
  let siger = '';
  if(token){
    siger= appid+'#'+appkey+'#'+token+'#'+timestamp+'#'+clientType+'#'+deviceType
  }else{
    siger= appid+'#'+appkey+'#'+timestamp+'#'+clientType+'#'+deviceType
  }
  const sign = MD5(siger).toString();
  const requestData = {}
  requestData.appId = appid;
  requestData.sign = sign;
  requestData.timestamp = timestamp;
  requestData.deviceType = deviceType;
  requestData.clientType = clientType;
  requestData.deviceVendor = deviceVendor;
  if(token){
    requestData.token = token;
  }
  requestData.baoName = baoming;
  requestData.data = config.data;
  config.data = requestData;
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: api.apiArray.HTTP_USER,
      method:'post',
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
    })
    // 发送网络请求
    instance(config).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export function request (config) {
  var timer = getTime()
  var token = md5(api.apiArray.KEY_ONLINE + timer)
  var tokenJson = localStorage.getItem('token');
  console.log('json',tokenJson)
  var TokenData = tokenJson!=''?JSON.parse(tokenJson):null;
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: api.apiArray.HTTP_URL_HEAD,
      timeout: 20000,
      headers: {
        'C-TOKEN': token,
        'C-TIME': timer,
        'C-PID': api.apiArray.PID_ONLINE,
        'C-CHANNEL': 'h5',
        'C-PACKNAME': 'com.middertbx.mobile',
        'C-APP-VERSION': 'V1.0',
        'userkey': TokenData==null?'':TokenData.userkey
      }
    })
    // 发送网络请求
    instance(config).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
