// 如果没有通过拦截器配置域名的话，可以在这里写上完整的URL(加上域名部分，实际开发不加域名而是通过nginx转发),参数为(url, data:{}, header:{})

const apiArray = {
  // 测试接口
  HTTP_URL_HEAD_TEST: 'https://dev.tongbuxueapp.com/api/v1_0_0',
  // 正式接口 https://www.tongbuxueapp.com/api/
  HTTP_URL_HEAD: 'https://www.tongbuxueapp.com/api/v1_0_0',

  HTTP_USER: 'http://api.lincheng.shop',

  HTTP_HEAD: 'http://api.lincheng.shop/ly-server',

  KEY_ONLINE: '20200421104146tDIjWcyaaYorMdjHrP',

  KEY_TEST: '20201028100003DFOQrZyOvtEbYtiqNJ',

  PID_ONLINE: '2',

  PID_TEST: '22',

  APP_ID:'EAEA2A4016715944',

  APP_KEY :'FA5649E2EAEA2A40167159445B1F6ADD',

  BAO_NAME: 'com.hdl.mskt',

  VIDEO_KEY: '6c5f1ed45b0048fdb184ae3db3bc2c88',


  //* *******************************************用户类****************************************************/
  SEN_MSG: '/common/sendverifycode',

  LOGIN_IN: '/user/authlogin',

  TOP_CLASS: '/liyue/get_two_lists',

  MATERIAL_BOOK:'/liyue/get_material_lists_prod',

  VIDEO_LIST: '/liyue/get_video_lists',

  VIDEO_ITEM: '/liyue/get_video_item',

  QUERY_USE: '/user/query',
  /**
   * 注册
   */
  USERS_REGISTER: '/account/register',
  /**
   * 注册2
   */
  USERS_REGISTER_2: '/user/add',
  /**
   * 登录
   */
  USERS_LOGIN: '/account/login',
  /**
   * 获取验证码
   */
  USERS_GET_CODE: '/phone/get_code',
  /**
   *退出登录
   */
  USERS_LOGOUT: '/account/logout',
  /**
   *更换密码
   */
  USERS_CHPWAD: '/account/chpwd',
  /**
   *书架列表
   */
  USERS_BOOKRACK: '/account/bookrack',
  /**
   *添加书架
   */
  USERS_ADD_BOOKRACK: '/account/add_bookrack',
  /**
   *删除书架
   */
  USERS_REMOVE_BOOKRACK: '/account/remove_bookrack',
  /**
   *收藏书架
   */
  USERS_FAVORITES: '/account/favorites',
  /**
   *添加收藏
   */
  USERS_ADD_FAVORITES: '/account/add_favorites',
  /**
   *删除收藏
   */
  USERS_REMOVE_FAVORITES: '/account/remove_favorites',
  /**
   *获取个人信息
   */
  USERS_INFO: '/account/info',
  /**
   *修改个人信息
   */
  USERS_CHANGE_INFO: '/account/change_info',
  /**
   *上传的文件
   */
  USERS_OSS_UPLOAD: '/uploads/oss_upload',
  /**
   *删除文件
   */
  USERS_OSS_REMOVE: '/upload/oss_remove',
  /**
   *视频点击
   */
  USERS_CLICK: '/course/click',
  /**
   *删除历史记录
   */
  USERS_DELETE_CLICK: '/course/delete_click',
  /**
   *意见反馈
   */
  USERS_FEEDBACK_ADD: '/feedback/add',
  /**
   *获取反馈意见
   */
  USERS_GET_CODE_DATA: '/feedback/get_data',
  /**
   *获取微信openid
   */
  USERS_GET_USERINFO: '/weixin/get_userinfo',
  /**
   *用户注销
   */
  USERS_DELETE: '/account/delete',
  //* *******************************************用户类****************************************************/

  //* *******************************************内容相关****************************************************/
  /**
   *教材列表
   */
  // GET_LIST: '/book/get_lists',
  /**
   *课程列表
   */
  COURSE_GET_LISTS: '/course/get_lists',
  /**
   *商品列表
   */
  PRODUCTS_GET_LISTS: '/products/get_lists',
  /**
   *顶级分类接口
   */
  CATEGORY_GET_TOP: '/category/get_top',
  /**
   *分类接口
   */
  CATEGORY_GET_LISTS: '/category/get_lists',
  /**
   *广告接口
   */
  AD_GET_AD: '/ad/get_ad',
  /**
   *视频排序版
   */
  COURSE_TOP: '/course/top',
  /**
   *教材排行版
   */
  BOOK_TOP: '/book/top',
  /**
   *播放列表接口
   */
  COURS_MY_PALY: '/course/my_play',
  /**
   *版本接口
   */
  VERSION_GET_VERSION: '/version/get_version',
  /**
   *点读教材列表(已停止维护)
   */
  BOOK_GET_CLICK_BOOK: '/book/get_click_book',
  /**
   *点读内容
   */
  COURS_GET_CLICK_LISTS: '/course/get_click_lists',
  /**
   *点读下载
   */
  COURS_DOWN_CLICK_BOOK: '/course/down_click_book',
  /**
   *点读单元内获取关联视频接口
   */
  COURSE_CLICK_TO_VIDEO: '/course/click_to_video',
  /**
   *资料集列表
   */
  DOCUMENT_GET_LISTS: '/document/get_lists',
  /**
   *资料列表
   */
  DOCUMENT_GET_DOCUMENT_FILES: '/document/get_document_files',
  /**
   *专项列表接口
   */
  TOPIC_GET_LISTS: '/topic/get_lists',
  /**
   *专项详情接口
   */
  TOPIC_GET_DETAIL: '/topic/get_detail',
  /**
   *获取分类属性
   */
  ATTR_GET_ATTR_INFO: '/attr/get_attr_info',
  /**
   *根据顶级分类获取教材
   */
  BOOK_GET_LISTS_TOP_CATE: '/book/get_lists_top_cate',
  /**
   *初中教材接口
   */
  BOOK_GET_LISTS_CHUZHONG: '/book/get_lists_chuzhong',
  /**
   *课本推荐
   */
  RECOMMEND_GET_DETAIL: '/recommend/get_detail',
  /**
   *应用分类接口
   */
  CATEGORY_GET_LIDTS_BY_APP: '/category/get_lists_by_app',
  /**
   *应用顶级分类接口
   */
  CATEGORY_GET_TOP_BY_APP: '/category/get_top_by_app',
  /**
   *获取显示配置
   */
  CONF_GET_SHOW: '/conf/get_show',
  /**
   *跟据id获取教材
   */
  BOOK_GET_LISTS_BY_IDS: '/book/get_lists_by_ids',
  /**
   *课外课视频排序
   */
  COURSE_KWK_TOP: '/course/kwk_top',
  /**
   *根据年级获取教材
   */
  BOOK_GET_BOOKS_BY_GRADE: '/book/get_books_by_grade',
  /**
   *课外课教材排序
   */
  BOOK_KWK_TOP: '/book/kwk_top',
  /**
   *内容分类
   */
  ARTICLE_GET_CATEGORY: '/article/get_category',
  /**
   *文章列表
   */
  ARTICLE_GET_ARTICLE_LISTS: '/article/get_article_lists',
  //* *******************************************内容相关****************************************************/
  /**
   *我的订单
   */
  ORDER_GETLISTS: '/order/get_lists',
  /**
   * 下单
   */
  ORDER_ADD: '/order/add',
  /**
   * 订单支付
   */
  ORDER_PAY: '/order/pay',
  /**
   * 改变支付方式
   */
  ORER_CHANGE: '/order/change_payment',
  /**
   * 翻译接口
   */
  FANYI: 'http://api.fanyi.baidu.com/api/trans/vip/translate'
}
export default {
  apiArray
}
